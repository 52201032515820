<template>
  <div
    class="regular-text text-with-read-more"
    :class="themeColorClass"
    :style="cssAlignment"
  >
    <div
      v-if="appearance.fullWidthBackground"
      class="text-with-read-more__mask"
    />

    <div class="text-with-read-more__content content-container-template">
      <UiHeader
        v-if="header"
        :header="header"
        :subheader="subheader"
        :header-layout="headerLayout"
        :header-position="headerPosition"
        :header-size="headerSize"
        :theme-color="themeColor"
      />

      <T3HtmlParser :content="bodytext" />

      <ButtonBase :button="button" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UiCeTextProps } from '~/types'
import { ButtonBase, UiHeader } from '~ui/components'
import useHeaderAlignment from '~ui/composables/useHeaderAlignment'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeTextProps>()

const themeColorClass = useTheming(props.themeColor)
const cssAlignment = useHeaderAlignment(props)
</script>

<style lang="scss">
.text-with-read-more {
  position: relative;
  isolation: isolate;

  &__mask {
    background-color: color(white);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: z-index(below);
  }

  &__content {
    background-color: var(--color__background-color);
    padding: rem(32px);
  }
}
</style>
